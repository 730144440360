import { fetchFactory, postMultipart } from 'isotope-client'

export const getListeImportsProgrammes = () => fetchFactory('/questions/import-ksa/programmes')

export const getListeDerniersImports = () => fetchFactory('/questions/import-ksa/derniers')

export const creerImport = values => postMultipart('/questions/import-ksa', {
	body: values
})

export const supprimerImport = id => fetchFactory(`/questions/import-ksa/${id}`, {
	method: 'delete'
})