import {
	BandeauInfo,
	checkAuthorities,
	injectSession,
	PROFIL_UTILISATEUR,
	DownloadIcon,
	Error,
	injectFormSnackbar
} from '@oceane/ui'
import React from 'react'
import { compose } from 'redux'
import { Title, StaticDataTable, getStyles } from 'isotope-client'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import * as selectors from '../../services/sessionsPapierSelectors'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { checkPlanningExist, getSurveillance } from '../../services/sessionsPapierActions'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { push } from 'connected-react-router'

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const OngletSurveillance = ({ sessionPapier, classes, getSurveillance, snackError, checkPlanningExist, goToDetailSurveillancePage }) => {
	const [surveillance, setSurveillance] = React.useState({})
	const [customError, setCustomError] = React.useState(null)

	React.useEffect(() => {
		getSurveillance(sessionPapier.id)
			.then(({ result }) => setSurveillance(result))
	}, [sessionPapier])

	const downloadPlanning = (idSite) => {
		return checkPlanningExist(sessionPapier.id, idSite)
			.then(() => {
				download(`/files/surveillance/${sessionPapier.id}/${idSite}`)
				setCustomError(null)
			})
			.catch(e => {
				setCustomError([e.bodyError.globalErrors[0].code])
				snackError('Une erreur est survenue')
			})
	}

	return (
		<>
			<Error error={customError} />
			{!surveillance.isValide && <BandeauInfo message="L'état de la session ne permet pas d'accéder à cette fonctionnalité" />}
			{surveillance.isValide && <>
				<Title value="Sites ouverts pour la session" />
				<StaticDataTable
					data={surveillance.listeSite}
					headers={[{
						key: 'libelle',
						name: 'Site',
						sortable: false,
						render: data => <Typography className={classes.libelleColumn} onClick={() => goToDetailSurveillancePage(sessionPapier.id, data.idSite)}>{data.libelle}</Typography>
					}, {
						key: 'planning',
						name: 'Planning',
						sortable: false,
						render: data => <IconButton onClick={() => downloadPlanning(data.idSite)}>
							<DownloadIcon />
						</IconButton>
					}, {
						key: 'convocations',
						name: 'Convocations',
						sortable: false,
						render: data => <IconButton onClick={() => download(`/files/surveillance/${sessionPapier.id}/convocation/${data.idSite}`)}>
							<DownloadIcon />
						</IconButton>
					}]}
				/>
			</>}
		</>
	)
}

const actions = {
	getSurveillance,
	checkPlanningExist,
	goToDetailSurveillancePage : (idSession, idSite) => dispatch => dispatch(push(`/sessions-papier/${idSession}/surveillance/${idSite}`))
}

OngletSurveillance.propTypes = {
	sessionPapier: PropTypes.object,
	getSurveillance: PropTypes.func,
	goToDetailSurveillancePage: PropTypes.func
}

const mapStateToProps = (state) => ({
	sessionPapier: selectors.getDonneesGenerales(state)
})

export default compose(
	connect(mapStateToProps, actions),
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE,
		PROFIL_UTILISATEUR.GESTIONNAIRE_LOGISTIQUE_EXAMEN
	),
	injectSession,
	withStyles(style),
	injectFormSnackbar()
)(OngletSurveillance)
