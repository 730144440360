import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Actions, Button, checkAuthorities, EmptyResult, PROFIL_UTILISATEUR } from '@oceane/ui'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import IconAbsence from '@material-ui/icons/Hotel'
import { injectToolbarData, StaticDataTable, Title } from 'isotope-client'
import { injectEpreuveSession } from './service/injectEpreuveSession'
import { getFilArianeEpreuveSession } from './service/FilArianeEpreuveSession'
import * as selectors from './service/correctionCopiesSelectors'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { corrigerCopie, declarerAbsence } from './service/correctionCopiesActions'

const GestionAnomaliesPages = ({
								   snackError,
								   snackSuccess,
								   corrigerCopie,
								   epreuveSession,
								   declarerAbsence,
								   goBackToEpreuve,
								   goToTraitementAnomalies,
								   goBackToListCorrectionCopies,
								   resultatControleEtCorrection
							   }) => {
	const [isCorrectionLoading, setIsCorrectionLoading] = React.useState(false)

	if (resultatControleEtCorrection) {
		const isAnomalie = resultatControleEtCorrection.nbreCopiesEnAnomalieNiveau1 > 0 || resultatControleEtCorrection.nbreCopiesEnAnomalieNiveau2 > 0
		const correctionPossible = resultatControleEtCorrection.nbreCopiesEnAnomalieNiveau1 === 0
		return <>
			{epreuveSession.isFichierCharge && <>
				<Title value="Anomalies" />
				<Typography>Nombre de copies en anomalie de niveau
					1&nbsp;: {resultatControleEtCorrection.nbreCopiesEnAnomalieNiveau1}</Typography>
				<Typography>Nombre de copies en anomalie de niveau
					2&nbsp;: {resultatControleEtCorrection.nbreCopiesEnAnomalieNiveau2}</Typography>
				<Actions>
					<Button
						variant="contained"
						disabled={!isAnomalie}
						onClick={() => goToTraitementAnomalies(epreuveSession.id)}
					>
						Traitement anomalies
					</Button>
				</Actions>
			</>}
			{resultatControleEtCorrection.listeCopiesNonRendues.length > 0 && <>
				<Title value="Copies non rendues" />
				<Typography>Nombre de copies non
					rendues&nbsp;: {resultatControleEtCorrection.listeCopiesNonRendues.length}</Typography>
				<StaticDataTable
					data={resultatControleEtCorrection.listeCopiesNonRendues}
					headers={[
						{
							key: 'numeroInscription',
							type: 'string',
							name: 'N° inscription',
							sortable: false
						},
						{
							key: 'nom',
							type: 'string',
							name: 'Nom',
							sortable: false,
							render: data => data.candidat.nomNaissance
						},
						{
							key: 'action',
							name: 'Marquer comme absent',
							render: data => {
								if(resultatControleEtCorrection.nbreCopiesEnAnomalieNiveau1 > 0) {
									return <Tooltip
										title="Il reste des anomalies de niveau 1"
									>
										<span><IconButton
											color="primary"
											disabled
										>
											<IconAbsence />
										</IconButton></span>
									</Tooltip>
								}
								return <IconButton
										color="primary"
										onClick={() => {
											declarerAbsence(data.idEpreuveSession, data.epreuveInscriptionPapier.id, data.numeroInscription)
												.catch(() => snackError())
										}}
									>
										<IconAbsence />
									</IconButton>
							},
							sortable: false
						}
					]}
					noResultFragment={<EmptyResult />}
				/>
			</>}
			<Title value="Correction des copies" />
			<Actions>
				{!correctionPossible && <Tooltip title="Il reste des anomalies à traiter"><span><Button
					variant="contained"
					disabled>
					Correction
				</Button></span></Tooltip>}
				{correctionPossible && <Button
					variant="contained"
					loading={isCorrectionLoading}
					onClick={() => {
						setIsCorrectionLoading(true)
						corrigerCopie(epreuveSession.id, epreuveSession.isFichierCharge)
							.then(() => {
								setIsCorrectionLoading(false)
								goBackToListCorrectionCopies()
								snackSuccess()
							})
							.catch(() => {
								setIsCorrectionLoading(false)
								snackError()
							})
					}}
				>
					Correction
				</Button>}
			</Actions>
		</>
	} else {
		// Si l'utilisateur tente d'accéder à la page sans être passé par le calcul
		// controle/correction de l'étape précédente, il est redirigé vers cette étape
		goBackToEpreuve(epreuveSession.id)
		snackError()
		return null
	}
}

const mapStateToProps = state => ({
	epreuveSession: selectors.getEpreuveSession(state),
	resultatControleEtCorrection: selectors.getResultatControleEtCorrection(state)
})

const actions = {
	corrigerCopie,
	declarerAbsence,
	goToTraitementAnomalies: idEpreuveSession => dispatch => dispatch(push(`/correction-copies/epreuve/${idEpreuveSession}/traitement-anomalies`)),
	goBackToListCorrectionCopies: () => dispatch => dispatch(push(`/correction-copies`)),
	goBackToEpreuve: idEpreuveSession => dispatch => dispatch(push(`/correction-copies/epreuve/${idEpreuveSession}`))
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.GESTIONNAIRE_BANQUE_QUESTIONS
	),
	connect(mapStateToProps, actions),
	injectEpreuveSession,
	injectToolbarData(({ epreuveSession }) => ({
		...getFilArianeEpreuveSession(
			'Correction d\'une épreuve',
			epreuveSession ? epreuveSession.libelleSession : '',
			epreuveSession ? epreuveSession.libelleEpreuveType : ''
		)
	})),
	injectSnackActions
)(GestionAnomaliesPages)