import { EmptyResult, FloatingButton, Link, ListFiltres, StyledTypography } from '@oceane/ui'
import { injectToolbarData, SpringDataTable, Title } from 'isotope-client'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import NouvelleAnnexePopup from './components/NouvelleAnnexePopup'
import PropTypes from 'prop-types'
import * as annexeActions from './services/annexeAction'
import * as annexeSelectors from './services/annexeSelectors'

const ListeAnnexe = (
	{
		match: { params },
		location: { state },
		handleQuery,
		resetQuery,
		searchValue
	}
) => {
	const [open, setOpen] = React.useState(false)
	const handleOpenPopup = () => setOpen(true)
	const handleClosePopup = () => setOpen(false)

	return (
		<React.Fragment>
			<Title value="Liste des annexes de la matière" />
			<ListFiltres
				handleQueryValue={handleQuery}
				resetQueryValue={resetQuery}
				searchValue={searchValue}
				searchPlaceholderId="references.annexes.search"
			/>

			<SpringDataTable
				apiUrl={`/matiere/${params.idMatiere}/annexes`}
				headers={[
					{
						id: 'numero',
						key: 'numero',
						name: 'Numéro',
						sortable: true,
						render: row => <Link to={`/annexes/${params.idMatiere}/${row.id}`}
											 state={{ code: state.code }}>{row.numero}</Link>
					},
					{
						id: 'extension',
						key: 'extension',
						name: 'Extension',
						sortable: true
					},
					{
						id: 'isAImprimer',
						key: 'isAImprimer',
						name: 'A imprimer',
						sortable: true,
						render: row => row.isAImprimer ? 'Oui' : 'Non'
					}
				]}
				nom="listeAnnexe"
				noResultFragment={<EmptyResult />}
				filters={{
					numero: searchValue
				}}
				defaultPageSize={50}
			/>
			<FloatingButton onClick={handleOpenPopup} />

			<NouvelleAnnexePopup
				open={open}
				closePopup={handleClosePopup}
				idMatiere={params.idMatiere}
				code={state.code}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => ({
	searchValue: annexeSelectors.getSearchValue(state)
})

const actions = {
	handleQuery: annexeActions.handleQuery,
	resetQuery: annexeActions.resetQuery
}

ListeAnnexe.propTypes = {
	handleQuery: PropTypes.func.isRequired,
	resetQuery: PropTypes.func.isRequired,
	searchValue: PropTypes.string.isRequired
}

export default compose(
	injectToolbarData(({ location: { state } }) => ({
		title: 'Annexe',
		subheader: {
			filArianeEntries: [{
				text: `Matière ${state.code}`,
				render: text => <StyledTypography bold>{text}</StyledTypography>
			}]
		}
	})),
	connect(mapStateToProps, actions)
)(ListeAnnexe)
