import {
	Actions,
	BandeauInfo,
	Button,
	checkAuthorities,
	DownloadIcon,
	Error,
	injectSession,
	PROFIL_UTILISATEUR,
	ResponsiveForm,
	Select,
	injectFormSnackbar
} from '@oceane/ui'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import { compose } from 'redux'
import MenuItem from '@material-ui/core/MenuItem'
import { getStyles, StaticDataTable, Title } from 'isotope-client'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { loadSujet } from '../services/SessionPapierInjector'
import {
	checkAnnexeExist,
	checkAnnexesExist,
	checkSujetExist,
	getSujet
} from '../../../../elaborersujets/generersujets/services/epreuveSujetAction'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { download } from 'isotope-client/components/download/IsotopeLink'

const style = () => getStyles({
	libelleColumn: {
		fontWeight: 'bold',
		'&:hover': {
			cursor: 'pointer',
		}
	}
})

const OngletEditionSujets = ({ sessionPapier, epreuve, getSujet, classes, checkAnnexeExist, snackError, checkAnnexesExist, checkSujetExist, submitting }) => {
	const [customError, setCustomError] = React.useState(null)
	const [sujet, setSujet] = React.useState({})
	const serie = [{
		name: 'Français',
		type: 1,
		...sujet.listeSeries
	}]
	const messageInfo = 'L\'état de la session ne permet pas d\'accéder à cette fonctionnalité'

	if (sujet.isBilingue) {
		serie.push({
			name: 'Anglais',
			type: 2,
			...sujet.listeSeries
		})
	}

	React.useEffect(() => {
		if (epreuve !== undefined) {
			getSujet(epreuve)
				.then(({ result }) => {
					setSujet(result)
				})
		}
	}, [epreuve])

	const downloadAnnexe = (id, nom) => {
		return checkAnnexeExist(id, nom)
			.then(() => {
				download(`/files/sujet/annexe/${id}`)
				setCustomError(null)
			})
			.catch(e => {
				setCustomError([e.bodyError.globalErrors[0].code])
				snackError('Une erreur est survenue')
			})
	}

	const downloadAnnexes = () => {
		return checkAnnexesExist(epreuve)
			.then(() => {
				download(`/files/sujet/annexes/${epreuve}`)
				setCustomError(null)
			})
			.catch(e => {
				setCustomError(e._error)
				snackError('Une erreur est survenue')
			})
	}

	const downloadSujet = (type, idSujet, numeroSerie) => {
		return checkSujetExist(epreuve, type, idSujet, numeroSerie)
			.then(() => {
				download(`/files/sujet/${epreuve}/${type}/${idSujet}/${numeroSerie}`)
				setCustomError(null)
			})
			.catch(e => {
				setCustomError([e.bodyError.globalErrors[0].code])
				snackError('Une erreur est survenue')
			})
	}

	return (
		<>
			{!sessionPapier.isValide && <BandeauInfo message={messageInfo} />}
			<Error error={customError} />
			{sessionPapier.isValide && <>
				<ResponsiveForm>
					<Field
						id="epreuve"
						name="epreuve"
						label="Epreuve"
						component={Select}
					>
						{sessionPapier.listeEpreuveSession && sessionPapier.listeEpreuveSession.length !== 0 && sessionPapier.listeEpreuveSession.map((epreuve) =>
							<MenuItem key={epreuve.id} value={epreuve.id}>
								{epreuve.libelle}
							</MenuItem>
						)}
					</Field>
				</ResponsiveForm>
				{(sujet.listeSeries && sujet.listeSeries.length > 0) && <>
					<Title value="Sujets" />
					<StaticDataTable
						data={serie}
						headers={[{
							key: 'name',
							sortable: false
						},
							{
								key: '0',
								name: 'Série 1',
								sortable: false,
								render: data => <IconButton
									onClick={() => downloadSujet(data.type, data[0].id, data[0].numeroSerie)}>
									<DownloadIcon />
								</IconButton>
							},
							{
								key: '1',
								name: 'Série 2',
								sortable: false,
								render: data => <IconButton
									onClick={() => downloadSujet(data.type, data[1].id, data[1].numeroSerie)}>
									<DownloadIcon />
								</IconButton>
							},
							{
								key: '2',
								name: 'Série 3',
								sortable: false,
								render: data => <IconButton
									onClick={() => downloadSujet(data.type, data[2].id, data[2].numeroSerie)}>
									<DownloadIcon />
								</IconButton>
							},
							{
								key: '3',
								name: 'Série 4',
								sortable: false,
								render: data => <IconButton
									onClick={() => downloadSujet(data.type, data[3].id, data[3].numeroSerie)}>
									<DownloadIcon />
								</IconButton>
							}]}
					/>
				</>}
				{sujet.listeAnnexes && sujet.listeAnnexes.length > 0 && <>
					<Title value="Annexes" />
					<StaticDataTable
						data={sujet.listeAnnexes}
						headers={[{
							key: 'nom',
							name: 'Nom',
							sortable: false,
							render: data => <Typography className={classes.libelleColumn}
														onClick={() => downloadAnnexe(data.id, data.nom)}>{data.nom}</Typography>
						}]}
					/>
					<Actions>
						<Button
							loading={submitting}
							color="primary"
							variant="contained"
							onClick={downloadAnnexes}
						>
							Imprimer annexes
						</Button>
					</Actions>
				</>}
			</>}
		</>
	)
}

const actions = {
	getSujet,
	checkAnnexeExist,
	checkAnnexesExist,
	checkSujetExist
}

OngletEditionSujets.propTypes = {
	sessionPapier: PropTypes.object,
	customError: PropTypes.string,
	epreuve: PropTypes.number,
	getSujet: PropTypes.func,
	checkAnnexeExist: PropTypes.func,
	checkAnnexesExist: PropTypes.func,
	checkSujetExist: PropTypes.func
}

const mapStateToProps = (state) => {
	const epreuve = formValueSelector('listeEpreuveTypeForm')(state, 'epreuve')

	return ({
		epreuve
	})
}

export default compose(
	loadSujet,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'listeEpreuveTypeForm'
	}),
	checkAuthorities(
		PROFIL_UTILISATEUR.RESPONSABLE_LOGISTIQUE
	),
	injectSession,
	withStyles(style),
	injectFormSnackbar()
)(OngletEditionSujets)
