import React from 'react'
import { compose } from 'redux'
import { StaticDataTable, Title, getStyles } from 'isotope-client'
import { injectAnnexes } from './services/BanqueQuestionsInjectors'
import PropTypes from 'prop-types'
import { Button, EmptyResult } from '@oceane/ui'
import { BNQ_ID_ETAT_QUESTION } from '../../../config/enums'
import { getDonneesGenerales } from './services/banqueQuestionsSelectors'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { checkFileExist } from '../../references/annexe/services/annexeAction'
import { updateAnnexeQuestion } from './services/banqueQuestionsActions'

const styles = (theme) => getStyles({
	button: {
		color: `${theme.palette.primary.main} !important`
	},
	linkFile: {
		cursor: 'pointer',
		fontWeight: 'bold'
	}
})

const OngletAnnexes = (
	{
		classes,
		annexesDisponibles,
		annexesAffectees, donneesGenerales,
		checkFileExist,
		snackSuccess,
		snackError,
		updateAnnexeQuestion
	}
) => {
	const clickDownload = (idAnnexe, fichierNom) =>
		checkFileExist(idAnnexe, donneesGenerales.idMatiere, fichierNom)
			.then(() =>
				download(`/files/annexes/${fichierNom}/${donneesGenerales.idMatiere}`)
			)
			.catch(e => {
				if (e && e.bodyError && e.bodyError.globalErrors && e.bodyError.globalErrors.length > 0) {
					snackError(e.bodyError.globalErrors[0].code)
				}
			})

	const getDataTableColumnHeaders = () => {
		return [
			{
				key: 'numero',
				type: 'string',
				name: 'Numéro',
				sortable: true
			},
			{
				key: 'fichier',
				type: 'string',
				name: 'Fichier',
				render: row => <span className={classes.linkFile} onClick={() => clickDownload(row.id, row.fichier)}>{row.fichier}</span>,
				sortable: true,
			},
			{
				key: 'fichierImage',
				type: 'string',
				name: 'Image',
				render: row => <span className={classes.linkFile} onClick={() => clickDownload(row.id, row.fichierImage)}>{row.fichierImage}</span>,
				sortable: true
			},
			{
				key: 'isAImprimer',
				type: 'boolean',
				name: 'Imprimer',
				render: row => row.isAImprimer ? 'Oui' : 'Non',
				sortable: true
			}
		]
	}

	const modifierQuestionAnnexe = (idAnnexe, isAffectation) => {
		updateAnnexeQuestion(donneesGenerales.idQuestion, idAnnexe, isAffectation)
			.then(() => snackSuccess())
			.catch(() => snackError())
	}

	return (<>
		<Title value="Affectées" />
		<StaticDataTable
			headers={[
				...getDataTableColumnHeaders(),
				{
					key: 'action',
					name: '',
					render: row => (donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.CREEE || donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.REFUSEE || donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.SUPPRIMEE) &&
						<Button
							className={classes.button}
							onClick={() => modifierQuestionAnnexe(row.id, false)}
						>
							Retirer
						</Button>
				}
			]}
			data={annexesAffectees}
			defaultPageSize={10}
			noResultFragment={<EmptyResult />}
		/>
		<Title value="Disponibles" />
		<StaticDataTable
			headers={[
				...getDataTableColumnHeaders(),
				{
					key: 'action',
					name: '',
					render: row => (donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.CREEE || donneesGenerales.etat === BNQ_ID_ETAT_QUESTION.REFUSEE) &&
						<Button
							className={classes.button}
							onClick={() => modifierQuestionAnnexe(row.id, true)}
						>
							Affecter
						</Button>
				}
			]}
			data={annexesDisponibles}
			defaultPageSize={10}
			noResultFragment={<EmptyResult />}
		/>
	</>)
}

const mapStateToProps = state => ({
	donneesGenerales: getDonneesGenerales(state)
})

const actions = {
	checkFileExist,
	updateAnnexeQuestion
}

OngletAnnexes.propTypes = {
	classes: PropTypes.object,
	annexesDisponibles: PropTypes.array,
	annexesAffectees: PropTypes.array,
	donneesGenerales: PropTypes.object,
	checkFileExist: PropTypes.func,
	snackError: PropTypes.func,
	updateAnnexeQuestion: PropTypes.func
}

export default compose(
	connect(mapStateToProps, actions),
	injectAnnexes,
	withStyles(styles),
	injectSnackActions,
)(OngletAnnexes)
